import { Button } from "antd";
import { getNextButtonTitle } from "./utils/getNextButtonTitle";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { usePortalExportContext } from "../portal/PortalExportContext";
import { useColumnValidation } from "../portal/useColumnValidation";
import { isNextButtonDisabled } from "./utils/isNextButtonDisabled";
import { getNextButtonText } from "./utils/getNextButtonText";

export const FooterNextButton = ({
  handleNextButtonClick,
  currentStep,
  isStartingExport,
}: {
  handleNextButtonClick: () => void;
  currentStep: number;
  isStartingExport: boolean;
}) => {
  const { selectedAssetBatches } = useFeedContext();
  const {
    exportDestination,
    adType,
    validity: adLibraryValidity,
  } = useAdLibraryExportContext();
  const { formValues } = usePortalExportContext();
  const { validateBrandsColumnQuery, validateAccountsColumnQuery } =
    useColumnValidation();
  const isBrandColumnValid =
    !validateBrandsColumnQuery.isLoading &&
    validateBrandsColumnQuery.data === true;
  const isAccountColumnValid =
    !validateAccountsColumnQuery.isLoading &&
    validateAccountsColumnQuery.data === true;

  const { brandId, accountId } = formValues;

  return (
    <Button
      onClick={handleNextButtonClick}
      type="primary"
      disabled={isNextButtonDisabled({
        currentStep,
        exportDestination,
        adType,
        brandId,
        accountId,
        selectedAssetBatches,
        adLibraryValidity,
        isBrandColumnValid,
        isAccountColumnValid,
      })}
      loading={isStartingExport}
      title={getNextButtonTitle(
        exportDestination,
        selectedAssetBatches,
        currentStep,
      )}
    >
      {getNextButtonText(exportDestination, currentStep)}
    </Button>
  );
};
