import { hasColumnInvalidData } from "../../adLibrary/utils/validateRowData";
import {
  AdType,
  ExportDestination,
  RecommendedColumnTitle,
  ValidityRecord,
} from "../types";

export const isNextButtonDisabled = ({
  currentStep,
  exportDestination,
  adType,
  brandId,
  accountId,
  selectedAssetBatches,
  adLibraryValidity,
  isBrandColumnValid,
  isAccountColumnValid,
}: {
  currentStep: number;
  exportDestination: ExportDestination | null;
  adType: AdType | null;
  brandId: string | undefined;
  accountId: string | undefined;
  selectedAssetBatches: string[];
  adLibraryValidity: Record<string, ValidityRecord>;
  isBrandColumnValid: boolean;
  isAccountColumnValid: boolean;
}) => {
  const isPortalExportValid = () => {
    const isBrandValid = brandId !== "" || isBrandColumnValid;
    const isAccountValid = !!accountId || isAccountColumnValid;

    return isBrandValid && isAccountValid;
  };

  const isStep1Disabled = () => {
    const isAdLibraryExport = exportDestination === ExportDestination.AdLibrary;
    const isPortalExport = exportDestination === ExportDestination.Portal;

    if (isAdLibraryExport) return !adType;
    if (isPortalExport) return !isPortalExportValid();
    return false;
  };

  const isStep2Disabled = () => {
    if (exportDestination !== ExportDestination.AdLibrary) return false;

    return (
      hasColumnInvalidData(
        adLibraryValidity,
        RecommendedColumnTitle.CTAButton,
      ) ||
      hasColumnInvalidData(
        adLibraryValidity,
        RecommendedColumnTitle.DestinationURL,
      )
    );
  };

  switch (currentStep) {
    case 0:
      return selectedAssetBatches.length === 0 || !exportDestination;
    case 1:
      return isStep1Disabled();
    case 2:
      return isStep2Disabled();
    default:
      return false;
  }
};
