import { useV3Client } from "shared/hooks/av3/useV3Client";
import { useV3Accounts } from "shared/hooks/av3/useV3Accounts";
import { useV3Brands } from "shared/hooks/av3/useV3Brands";
import { useV3Clients } from "shared/hooks/av3/useV3Clients";
import { useEffect, useState } from "react";
import { isEnvVarEquals } from "utils/helpers";
import { ClientFragment } from "graphql/v3.gen";

export const usePortalExportData = () => {
  const [client, setClient] = useState<ClientFragment | undefined>(undefined);

  const { client: clientData, loading: clientLoading } = useV3Client();
  const { clients: clientsData, loading: clientsLoading } = useV3Clients();
  const { brands: brandsData, loading: brandsLoading } = useV3Brands(
    client?.id,
  );
  const { accounts: accountsData, loading: accountsLoading } = useV3Accounts(
    client?.id,
  );

  useEffect(() => {
    const isInternalEnv =
      isEnvVarEquals("CLIENT", "av3") || isEnvVarEquals("CLIENT", "internal");

    // for internal envs, the client is set by the user
    // for external envs, the client is set here automatically
    if (!isInternalEnv && clientData) {
      setClient(clientData);
    }
  }, [clientData]);

  return {
    client,
    clients: clientsData,
    brands: brandsData,
    accounts: accountsData,
    loading:
      clientLoading || brandsLoading || accountsLoading || clientsLoading,
    setClient,
  };
};
